/** @jsx jsx */
import {
  jsx,
  Box,
  Button,
  Card,
  Container,
  Flex,
  Grid,
  Styled,
  Text,
} from "theme-ui"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import BackgroundImage from "gatsby-background-image"
import { HelmetDatoCms } from "gatsby-source-datocms"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

import {
  Hero,
  Overlay,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/elements"
import {
  Layout,
  Main,
  Section,
} from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"

import {
  ColorBar,
  HeroGradient,
  PatientStoryGrid,
  QuickLink,
  QuickLinks,
  SectionHeading,
} from "../../components/elements"

const Patients = ({ data, pageContext }) => {
  const page = data.datoCmsPatientVisitor
  const {
    breadcrumb: { crumbs },
  } = pageContext

  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <Main>
        <Hero>
          <BackgroundImage Tag="div" fluid={page.heroImage.fluid}>
            <HeroGradient>
              <Container sx={{ px: "1em", py: [6, 6, 7] }}>
                <Styled.h1>{page.title}</Styled.h1>
                <Breadcrumb
                  crumbs={crumbs}
                  crumbSeparator=" / "
                  crumbLabel={page.title}
                />
              </Container>
            </HeroGradient>
            <ColorBar sx={{ position: "absolute", bottom: 0, left: 0 }} />
          </BackgroundImage>
        </Hero>

        {/* IntroContent */}
        <Section id="intro">
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap" }}>
              <Box sx={{ width: ["full", "full", "4/5"] }}>
                <Styled.h6 as="p">{page.introContent}</Styled.h6>
              </Box>
              <Text
                variant="styles.twoCols"
                sx={{ width: ["full"] }}
                dangerouslySetInnerHTML={{
                  __html: page.contentNode.childMarkdownRemark.html,
                }}
              />
            </Flex>
          </Container>
        </Section>

        {/* Resources */}
        <Section id="resources" sx={{ backgroundColor: ["warmgray"] }}>
          <Container sx={{ px: "1em" }}>
            <Flex sx={{ flexWrap: "wrap", justifyContent: "center" }}>
              <Card variant="wide">
                <Box sx={{ width: ["full", "full", "1/2"] }}>
                  <Img
                    fluid={data.datoCmsHospitalStayPage.heroImage.fluid}
                    alt={"Image of your hospital stay"}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    sx={{
                      objectFit: "cover",
                      bg: "gray.3",
                      height: "full",
                    }}
                  />
                </Box>
                <Box sx={{ width: ["full", "full", "1/2"], p: 4 }}>
                  <Styled.h2>{data.datoCmsHospitalStayPage.title}</Styled.h2>

                  <p>{data.datoCmsHospitalStayPage.introContent}</p>

                  <Link
                    to={`/patients-visitors/${data.datoCmsHospitalStayPage.slug}`}
                  >
                    <Button as="span" variant="outline">
                      More About Your Stay
                    </Button>
                  </Link>
                </Box>
              </Card>

              <Card variant="wide">
                <Box sx={{ width: ["full", "full", "1/2"] }}>
                  <Img
                    fluid={data.datoCmsResource.heroImage.fluid}
                    alt={"Image of Visitor Resources"}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    sx={{
                      objectFit: "cover",
                      bg: "gray.3",
                      height: "full",
                    }}
                  />
                </Box>
                <Box sx={{ width: ["full", "full", "1/2"], p: 4 }}>
                  <Styled.h2>{data.datoCmsResource.title}</Styled.h2>

                  <p>{data.datoCmsResource.introContent}</p>

                  <Link to={`/patients-visitors/${data.datoCmsResource.slug}`}>
                    <Button as="span" variant="outline">
                      More Visitor Resources
                    </Button>
                  </Link>
                </Box>
              </Card>

              <Card variant="wide">
                <Box sx={{ width: ["full", "full", "1/2"] }}>
                  <Img
                    fluid={data.datoCmsMywvuChart.heroImage.fluid}
                    alt={"Image of WVUMyChart"}
                    objectFit="cover"
                    objectPosition="50% 50%"
                    sx={{
                      objectFit: "cover",
                      bg: "gray.3",
                      height: "full",
                    }}
                  />
                </Box>
                <Box sx={{ width: ["full", "full", "1/2"], p: 4 }}>
                  <Styled.h2>{data.datoCmsMywvuChart.title}</Styled.h2>

                  <p>{data.datoCmsMywvuChart.introContent}</p>

                  <Link
                    to={`/patients-visitors/${data.datoCmsMywvuChart.slug}`}
                  >
                    <Button as="span" variant="outline">
                      More About MyWVUChart
                    </Button>
                  </Link>
                </Box>
              </Card>
            </Flex>
          </Container>
        </Section>

        {/* Programs */}
        <Box
          as="section"
          id="programs"
          sx={{ backgroundColor: ["accent.2"], color: "white" }}
        >
          <BackgroundImage
            Tag="div"
            fluid={data.datoCmsProgramPage.heroImage.fluid}
          >
            <Container
              sx={{ position: "relative", px: "1em", py: 6, zIndex: 10 }}
            >
              <Flex sx={{ flexWrap: "wrap", width: "full", mx: -2 }}>
                <Box sx={{ width: ["full", "full", "full", "1/2"], px: 2 }}>
                  <SectionHeading>Programs</SectionHeading>
                  <p className="text-lg">
                    {data.datoCmsProgramPage.introContent}
                  </p>
                </Box>
                <Box sx={{ width: ["full", "full", "full", "1/2"], px: 2 }}>
                  <Grid gap={3} columns={[1, 2]} sx={{ gridAutoRows: "1fr" }}>
                    {data.allDatoCmsProgram.edges.map(({ node: program }) => (
                      <Flex
                        key={program.id}
                        sx={{
                          alignItems: "center",
                          borderColor: "gray.3",
                          borderRadius: "default",
                          borderStyle: "solid",
                          borderWidth: "1px",
                          flexWrap: "no-wrap",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link
                          to={`/patients-visitors/programs/${program.slug}`}
                          sx={{
                            color: "white",
                            height: "full",
                            textDecoration: "none",
                            width: "full",
                            "&:hover": {
                              backgroundColor: "white",
                              color: ["accent.2"],
                            },
                          }}
                        >
                          <Flex
                            sx={{
                              alignItems: "center",
                              justifyContent: "center",
                              flexWrap: "wrap",
                              fontWeight: "bold",
                              height: "full",
                              lineHeight: 1,
                              px: 2,
                              py: 3,
                              textAlign: "center",
                              textTransform: "uppercase",
                              width: "full",
                            }}
                          >
                            {program.title}
                          </Flex>
                        </Link>
                      </Flex>
                    ))}
                  </Grid>
                </Box>
              </Flex>
            </Container>
            <Overlay sx={{ opacity: 1 }} />
          </BackgroundImage>
        </Box>

        {/* Stories */}
        <Section data-datocms-noindex>
          <Container>
            <Box sx={{ width: "full", textAlign: "center", mb: 4 }}>
              <SectionHeading>Patient Stories</SectionHeading>
              <PatientStoryGrid />
              <br />
            </Box>
          </Container>
        </Section>

        {/* QuickLinks */}
        <QuickLinks>
          {page.quicklinks.map(
            ({ id, linkTitle, internalLinkUrl, extrenalLinkUrl }) => (
              <QuickLink
                key={id}
                to={extrenalLinkUrl || internalLinkUrl}
                title={linkTitle}
              />
            )
          )}
        </QuickLinks>
      </Main>
    </Layout>
  )
}

export default Patients

export const query = graphql`
  {
    datoCmsPatientVisitor {
      contentNode {
        childMarkdownRemark {
          html
        }
      }
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      id
      introContent
      slug
      title
      quicklinks {
        id
        linkTitle
        internalLinkUrl
        extrenalLinkUrl
      }
      seoMetaTags {
        tags
      }
    }
    datoCmsHospitalStayPage {
      contentNode {
        childMarkdownRemark {
          excerpt(pruneLength: 240, truncate: true)
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsResource {
      contentNode {
        childMarkdownRemark {
          excerpt(pruneLength: 240, truncate: true)
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsMywvuChart {
      contentNode {
        childMarkdownRemark {
          excerpt(pruneLength: 240, truncate: true)
        }
      }
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsProgramPage {
      id
      introContent
      slug
      title
      heroImage {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    allDatoCmsProgram {
      edges {
        node {
          title
          slug
          id
        }
      }
    }
  }
`
